<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Contas</h2>
              <p>
                Aqui você encontra todas as suas contas e com o saldo somado e o balanço do período.
              </p>
            </div>
          </div>
          <button class="btn btn-primary pull-right ml-5 action-income" type="button" v-modal="{ target: 'create-account-modal', data: {color: '#933DA8'} }"><span><i class="mdi mdi-plus-circle-outline"></i></span> Cadastrar uma conta </button>
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="owl-nav" style="position: relative; width: 30%; top: 0; margin: 0 auto; margin-top:20px;margin-bottom:23px;">
          <calendar-co :month="monthStart" :year="yearStart" :callBack="changeDate" :navigateTo="navigateDateTo"></calendar-co>
        </div>
      </div>
      <div class="col-lg-8 col-md-7 col-sm-12 col-xs-12">
        <div class="card" style="min-height: 280px;">
          <div class="card-body overflow">
            <div v-if="isLoading" class="row expense-info">
              <section class="col-sm-6">
                <div class="shimmerBG title-line"></div>
                <div class="shimmerBG title-line end"></div>
                <div style="margin-top:20px;"></div>
                <div class="shimmerBG title-line"></div>
                <div class="shimmerBG title-line end"></div>
                <div style="margin-top:20px;"></div>
                <div class="shimmerBG title-line"></div>
                <div class="shimmerBG title-line end"></div>
              </section>
              <section class="col-sm-6">
                <div class="shimmerBG title-line" style="height:30px; width: 60%;"></div>
                <div class="shimmerBG title-line end" style="height:25px;"></div>
              </section>
            </div>
            <div v-else class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="transaction-amount">
                  <!-- item -->
                  <div class="transaction-amount-item">
                    <div class="transaction-icon">
                      <i
                        class="mdi mdi-checkbox-blank-circle text-primary"
                      ></i>
                    </div>
                    <div class="transaction-info">
                      <strong>{{report.transactions.report.total_income | currency}}</strong>
                      <span>Receitas</span>
                    </div>
                  </div>
                  <!-- item -->
                  <div class="transaction-amount-item">
                    <div class="transaction-icon">
                      <i
                        class="mdi mdi-checkbox-blank-circle text-danger"
                      ></i>
                    </div>
                    <div class="transaction-info">
                      <strong>{{report.transactions.report.total_expense | currency}}</strong>
                      <span>Despesas</span>
                    </div>
                  </div>
                  <!-- item -->
                  <div class="transaction-amount-item">
                    <div class="transaction-icon">
                      <i class="mdi mdi-checkbox-blank-circle text-info"></i>
                    </div>
                    <div class="transaction-info">
                      <strong>{{report.transactions.report.total_transfer | currency}}</strong>
                      <span>Transferências</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h3 class="text-left block DINRoundPro" style="color: #938d8d">
                  Saldo do período
                </h3>
                <h1
                  class="text-left font-w-100 text-default DINRoundPro-Light"
                  style="font-size: 25px"
                >
                  {{report.transactions.report.total | currency}}
                </h1>

                <section class="text-center lets-info">
                  <div class="fast-add">
                    <ul class="btn-list">
                      <li class="btn-action">
                        <router-link
                          :to="'/expense'"
                          class="expense"
                        ><i class="icon-expense-dashboard"></i>
                          <p class="btn-title ng-scope" translate="">
                            DESPESAS
                          </p>
                        </router-link>
                      </li>
                      <li class="btn-action">
                        <router-link
                          :to="'/income'"
                          class="earning"
                        ><i class="icon-earning-dashboard"></i>
                          <p class="btn-title ng-scope" translate="">
                            RECEITAS
                          </p>
                        </router-link>
                      </li>
                      <li class="btn-action">
                        <router-link
                          :to="'/transfer'"
                          class="transfer"
                        ><i class="icon-transfer-dashboard"></i>
                          <p class="btn-title ng-scope" translate="">
                            TRANSF.
                          </p>
                        </router-link>
                      </li>
                      <li class="btn-action">
                        <router-link
                          :to="'/import'"
                          class="import"
                        ><i class="icon-import-dashboard"></i>
                          <p class="btn-title ng-scope" translate="">
                            IMPORTAR
                          </p>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
              <div class="col-lg-12" style="height:15px;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body relative" style="padding: 1px;">
            <div class="mini-tab-root tab-elevation1 tab-rounded" style=" width:328px !important; margin: 0 auto;">
              <div class="mini-tab-content" style="min-height: 28px;">
                  <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden; height: 35px;">
                    <div class="mini-tab-flex">
                        <button style="width: 160px" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                          <span style="margin-top: -10px;">
                            Despesas
                          </span>
                          <span class="MuiTouchRipple-root"></span>
                        </button>
                        <button style="width: 170px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 168)">
                          <span style="margin-top: -10px;">
                            Receitas
                          </span>
                          <span class="MuiTouchRipple-root"></span>
                        </button>
                    </div>
                    <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px; background: ${tabColor};`"></span>
                  </div>
              </div>
            </div>
            <div style="margin-top: 13px;"></div>
            <h4 class="absolute">Estatísticas</h4>
            <v-chart class="chart" :option="option" />
          </div>
        </div>
      </div>
    </div>
    <hr>
    <h4>Minhas contas</h4>
    <div class="row">
      <div class="col-sm-12 credit-cards-container">
        <div class="tab-content relative">
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card table-responsive longer" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">  
              <div class="row ng-scope" v-if="this.wallets.wallets.length == 0">
                <img :src="`assets/images/bank.svg`" class="nothing-image w-15p" style="width: 10% !important;">
                <h3 class="nothing-text">Nenhuma conta cadastrada</h3>
              </div>
              <div v-else class="row">
                <div class="columns is-gapless">
                  <div class="column is-8">
                    <!--Box-->
                    <div class="inner-box relative">
                      <div class="cards-wrapper" style="padding-left: 35px;padding-right: 35px;">
                        <div class="cards-carousel">
                          <div class=" cards-carousel-inner slick-initialized slick-slider slick-dotted">
                            <carousel :perPage="2" :perPageCustom="[[480, 1], [1115, 2]]" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="`<i class='fas fa-angle-right arrow-swipper'></i>`" :navigationPrevLabel="`<i class='fas fa-angle-left arrow-swipper'></i>`">
                              <slide v-for="(wallet, i) in this.wallets.wallets" :key="i" :data-index="i" @slideclick="handleSlideWallet">
                                <div class="item rounded card bg-white mt-5 box-card accounts-list relative" :style="`background: ${wallet.color} !important; width: 280px !important;`">
                                  <div class="padding-10">
                                    <h4>{{wallet.name}}</h4>
                                    <section class="text-center mt-15">
                                      <h1 class="text-left font-w-100">
                                        <span>{{wallet.balance | currency}}</span>
                                      </h1>
                                    </section>
                                    <span class="label label-success info-label-wallet" v-if="wallet.automatic">Automática</span>                         
                                    <div></div>
                                  </div>
                                </div>
                              </slide>
                            </carousel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <!--Box-->
                    <div class="inner-box">
                      <div class="box-title">
                        <h3>Saldo Total</h3>
                      </div>

                      <!--Balance-->
                      <div class="card-balance-wrap">
                        <div
                          class="card-balance text-success"
                          style="margin-bottom: 10px"
                        >
                          <span class="text-success font-w-600 DINRoundPro">{{this.wallets.total_balance | currency}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import VChart, { THEME_KEY } from "vue-echarts";
import { moneyFormat } from '@/plugins/functions';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import CalendarCo from '../components/Calendar.vue';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'Home',
  components: {
    CalendarCo,
    VChart,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      tabIncome: 'expense',
      tabColor: '#ff1a1a',
      cards: [],
      categories: [],
      wallets: {wallets: []},
      yearStart: 2023,
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Contas', value: 'account_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      option: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<div class="text-left"><h4 class="color-black no-margin text-left">' +
              params.name +
              " (" +
              params.percent +
              "%)</4><span style='display:block; color: #938d8d;'>R$ "+moneyFormat(params.value)+"</span></div>";

            return template;
          },
        },
        color: ["#57BE65", "#ff1a1a", "#3EA4F1"],
        series: [
          {
            name: "R$",
            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 1,
            },
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      monthStart: 0,
      from: '',
      to: '',
      report: {},
      boxFlow: {
        income: {},
        expense: {},
        total: {},
        group: {
          all: {
            item: [],
          },
          income: {
            item: [],
          },
          expenses: {
            item: [],
          }
        }
      },
      isLoading: true,
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    this.yearStart = Number(moment(new Date()).format('YYYY'));
    console.log('Mês', this.monthStart);
    console.log('Ano', this.yearStart);
    pace.start();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.optionsDate.startDate = `${moment(new Date()).format('01/MM/YYYY')}`;
    this.optionsDate.endDate = moment(this.from, "YYYY-MM-DD").endOf('month').format('DD/MM/YYYY');
    
    this.request();
    this.$root.$on('register.account', this.request);
  },
  methods: {
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
      switch (tab) {
        case 0:
          this.tabColor = '#ff1a1a';
          this.tabIncome = 'expense';
          break;
        case 1:
          this.tabColor = '#18b272';
          this.tabIncome = 'income';
          break;
        default:
          break;
      }
      this.request();
    },
    handleSlideWallet (dataset) {
      this.$router.push(`/account/detail/${this.wallets.wallets[dataset.index].id}`);
    },
    navigateDateTo (month) {
      console.log(month);
      this.from = `${month.year}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.request();
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request() {
      AppService.getWallets({}).then(
        (response) => {
          this.wallets = response;
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );

      AppService.getCategories({}).then(
        (response) => {
          this.categories = response.categories;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );

      AppService.getInfo({
        from: this.from,
        to: this.to,
        no_card: true,
      }).then(
        (response) => {
          this.isLoading = false;
          this.report = response;
          console.log('Income');
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
      AppService.getStatistics({
        remove_transfer: true,
        type: this.tabIncome,
        from: this.from,
        to: this.to,
      }).then(
        (response) => {
          console.log('Statistics');
          console.log(response);
          this.option.series[0].data = [];
          let color = [];
          response.statistics.forEach((item) => {
            this.option.series[0].data.push({
              value: item.value, name: item.accName,
            });
            color.push(item.color);
          });
          this.option.color = color;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.transaction-amount {
  width: 90%;
}
</style>
